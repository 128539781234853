import { Customer } from "./customers.js";

export interface UserTestimonial {
  body: string;
  author: {
    name: string;
    title?: string;
    imageUrl: string;
    logoUrl?: string;
  };
  company?: Customer;
}

enum TestimonialId {
  AVI = "avi",
  SETAREH = "setareh",
  BRYAN = "bryan",
  OSCAR = "oscar",
  SID = "sid",
  EVAN = "evan",
  FINN = "finn",
  TIM = "tim",
  ANDREW = "andrew",
  PETER_SMYTH = "peter-smyth",
}

export const TESTIMONIAL_META: Record<TestimonialId, UserTestimonial> = {
  [TestimonialId.AVI]: {
    body: "**Windy** has been instrumental in helping us zoom in and out on the business. Looking back over the past 6 months or quarter with the same level of detail as though it were one week later has been super powerful for our exec team.",
    author: {
      name: "Avi Goren",
      title: "CEO",
      imageUrl:
        "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/testimonials/avi-goren.jpeg",
    },
    company: Customer.MARQII,
  },
  [TestimonialId.SETAREH]: {
    body: "Integrating **Windy** into our workflow was seamless. Our employees adopted it immediately because it actually makes their jobs easier. It has significantly streamlined our performance management process and practically put it on autopilot.",
    author: {
      name: "Setareh Lotfi",
      title: "CTO",
      imageUrl:
        "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/testimonials/setareh-lotfi.jpeg",
    },
    company: Customer.AURAINTL,
  },
  [TestimonialId.BRYAN]: {
    body: 'Windmill has been an incredible tool in helping our team deliver, receive and process feedback. Windy, their AI bot, makes providing feedback painless and the "real time" requests eliminates the recency bias of every other HR/Performance Management solution we\'ve used in the past.',
    author: {
      name: "Bryan Rutcofsky",
      title: "Co-Founder & CRO",
      imageUrl:
        "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/testimonials/bryan-rutcofsky.jpeg",
    },
    company: Customer.MARQII,
  },
  [TestimonialId.OSCAR]: {
    body: "The most important part of my job is people management. I used to really dread a lot of the chores that came with it, but Windmill makes it a snap for me and my team.",
    author: {
      name: "Oscar Mattsson",
      title: "CEO",
      imageUrl:
        "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/testimonials/oscar-matteson.jpeg",
    },
    company: Customer.ALLWHERE,
  },
  [TestimonialId.SID]: {
    body: "Windmill has eliminated the bureaucracy that used to slow us down. Our managers now lead more and manage less, resulting in happier teams and better business outcomes.",
    author: {
      name: "Sid Yadav",
      title: "Co-founder & CEO",
      imageUrl:
        "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/testimonials/sid-yadav.jpeg",
    },
    company: Customer.CIRCLE,
  },
  [TestimonialId.EVAN]: {
    body: "Windmill has been a great way for me to keep a pulse on my team. I can easily see what they are accomplishing, process and deliver feedback, clearly, confidently, quickly and easily.",
    author: {
      name: "Evan Perlmutter",
      title: "COO & Co-Founder",
      imageUrl:
        "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/testimonials/evan-perlmutter.jpeg",
    },
    company: Customer.MARQII,
  },
  [TestimonialId.FINN]: {
    body: "As a first time YC Founder, Windmill has been invaluable to help me run my team.",
    author: {
      name: "Finn Mallery",
      title: "Founder & CEO",
      imageUrl:
        "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/testimonials/finn-mallery.jpeg",
    },
  },
  [TestimonialId.TIM]: {
    body: "Windmill has completely transformed how I manage my team. The AI-powered system eliminates all the administrative overhead that used to consume my day. Now I can focus on actually leading my team instead of chasing updates and managing spreadsheets.",
    author: {
      name: "Tim Sedwitz",
      title: "Head of Revenue Operations",
      imageUrl:
        "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/testimonials/tim-sedwitz.jpeg",
    },
    company: Customer.SNORKEL,
  },
  [TestimonialId.ANDREW]: {
    body: "Really impressed with the vision from the Windmill team. This is clearly the future.",
    author: {
      name: "Andrew Yeung",
      imageUrl:
        "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/testimonials/andrew-yeung.jpeg",
    },
  },
  [TestimonialId.PETER_SMYTH]: {
    body: "The Windmill integration was fast and easy.  Very powerful to see everything in a single place - I feel like I have a much better sense of where our energy is going as team",
    author: {
      name: "Peter Smyth",
      title: "CEO",
      imageUrl:
        "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/testimonials/peter-smyth.jpeg",
    },
    company: Customer.LOCAL_LOCKER,
  },
};

export const PRIMARY_TESTIMONIALS = [TestimonialId.AVI, TestimonialId.SETAREH, TestimonialId.SID];

export const SECONDARY_TESTIMONIALS = [
  TestimonialId.OSCAR,
  TestimonialId.BRYAN,
  TestimonialId.FINN,
];

export const TERTIARY_TESTIMONIALS = [
  TestimonialId.TIM,
  TestimonialId.PETER_SMYTH,
  TestimonialId.ANDREW,
];

export const FEATURED_TESTIMONIALS = [
  ...PRIMARY_TESTIMONIALS,
  ...SECONDARY_TESTIMONIALS,
  ...TERTIARY_TESTIMONIALS,
];
