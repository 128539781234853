export enum Customer {
  ACUITYMD = "AcuityMD",
  ALLWHERE = "Allwhere",
  AURAINTL = "AuraIntel",
  FLUVIO = "Fluvio",
  CIRCLE = "Circle",
  HEYMILO = "HeyMilo",
  LEVITATE = "Levitate",
  LOCAL_LOCKER = "Local Locker",
  MARQII = "Marqii",
  REMOTEWORKMATE = "RemoteWorkmate",
  RHO = "Rho",
  ROAM = "Roam",
  ORIGAMI_AGENTS = "Origami Agents",
  SNORKEL = "Snorkel",
  KODDI = "Koddi",
}

interface CustomerMeta {
  name: string;
  logo?: string;
}

export const CUSTOMER_META: Record<Customer, CustomerMeta> = {
  [Customer.ACUITYMD]: {
    name: "AcuityMD",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/acuitymd.webp",
  },
  [Customer.ALLWHERE]: {
    name: "Allwhere",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/allwhere.png",
  },
  [Customer.AURAINTL]: {
    name: "Aura",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/auraintel.webp",
  },
  [Customer.FLUVIO]: {
    name: "Fluvio",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/fluvio.webp",
  },
  [Customer.HEYMILO]: {
    name: "HeyMilo",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/heymilo.png",
  },
  [Customer.LEVITATE]: {
    name: "Levitate",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/levitate.png",
  },
  [Customer.LOCAL_LOCKER]: {
    name: "Local Locker",
  },
  [Customer.MARQII]: {
    name: "Marqii",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/marqii.png",
  },
  [Customer.REMOTEWORKMATE]: {
    name: "RemoteWorkmate",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/remoteworkmate.png",
  },
  [Customer.RHO]: {
    name: "Rho",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/rho.png",
  },
  [Customer.ROAM]: {
    name: "Roam",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/roam.png",
  },
  [Customer.SNORKEL]: {
    name: "Snorkel",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/snorkel.png",
  },
  [Customer.CIRCLE]: {
    name: "Circle",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/circle.webp",
  },
  [Customer.ORIGAMI_AGENTS]: {
    name: "Origami Agents",
  },
  [Customer.KODDI]: {
    name: "Koddi",
    logo: "https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/customers/koddi.png",
  },
};

export const FEATURED_CUSTOMERS = [
  Customer.MARQII,
  Customer.ALLWHERE,
  Customer.SNORKEL,
  Customer.CIRCLE,
  Customer.HEYMILO,
  Customer.FLUVIO,
  Customer.AURAINTL,
  Customer.ROAM,
  Customer.RHO,
  Customer.KODDI,
];
